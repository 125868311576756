
import { mapGetters } from 'vuex'
import GlobalMixins from '~/mixins/globalMixins'
export default {
    mixins: [GlobalMixins],
    data() {
        return {
            isMobileScreen: false,
            links: {
                login: '/login',
            },
            filter: {
                start_date: null,
                end_date: null,
                page: 1,
                limit: 10,
            },
        }
    },

    computed: {
        ...mapGetters({
            languages: 'settings/languages',
        }),

        token() {
            return this.$store.state.player.token || this.$cookie.get('token')
        },
        loading() {
            return this.$store.state.settings.loading
        },
    },

    created() {
        if (this.$vuetify.breakpoint.mobile) {
            this.$vuetify.breakpoint.mobile = false
        }
        this.$vuetify.theme.dark = this.$cookie.get('darkMode') || false
    },
    async mounted() {
        await this.$store.dispatch('cms/cms')
        this.filter.start_date =
            this.$dayjs('2021').format('YYYY-MM-DD') + ' 00:00:00'

        this.filter.end_date = this.$dayjs().format('YYYY-MM-DD') + ' 23:59:59'
        await this.$store.dispatch('banks/accounts')
        await this.$store.dispatch(
            'themes/language-string/fetchLanguageStrings',
            this.handleCookie('wl_locale')
        )
        await this.setDefaultLanguages()
        this.$store.dispatch('settings/languages')
    },
    methods: {
        onResize() {
            if (process.browser) {
                this.isMobileScreen = window.innerWidth < 1300
                this.$store.commit(
                    'themes/settings/setIsMobile',
                    this.isMobileScreen
                )
                return null
            }
            this.isMobileScreen = false
            this.$store.commit(
                'themes/settings/setIsMobile',
                this.isMobileScreen
            )
        },
    },
}
